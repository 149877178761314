<template>
  <div>
    <div class="authortitle">期刊收藏</div>
    <div>
      <ul class="displayqk">
        <li
          v-for="item in qikan"
          @click="linktodetails(item.qikan_id)"
          style="cursor: pointer"
        >
          <img :src="item.cover" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div class="orderPage">
      <el-pagination
        class="fy"
        small
        @size-change="handleSizeChange"
        :page-size="pagesize"
        :page-sizes="[10, 20, 30]"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="current_change"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>
       

<script>

import { seefavorites } from "../../../API/ZZD/magzine.js"
export default {
  data () {
    return {
      qikan: [],
      total: 0, //默认数据总数
      pagesize: 8, //每页的数据条数
      loading: false,
      currentPage: 1, //默认开始页面
    }

  },
  mounted () {
    this.showmagzine()
  },
  methods: {
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.showmagzine()
    }, handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.showmagzine()
    },
    showmagzine () {
      seefavorites(localStorage.getItem('ZZId'), this.currentPage, this.pagesize).then(res => {
        
        this.qikan = res.data.data

        this.total = res.data.total
        for (let i = 0; i < this.qikan.length; i++) {
          this.qikan[i].cover = this.URL + this.qikan[i].cover
        }
      })
    },
    //跳转详情
    linktodetails (qkid) {
      this.$router.push({ path: "/Pending/contribute/detail", query: { "qkid": qkid } })
    },
  }
}

</script>

<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.displayqk {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}
.displayqk li {
  width: 22%;
  margin: 10px 10px;
}
.displayqk li img {
  width: 100%;
  height: 80%;
}
.orderPage {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.displayqk span {
  display: block;
  text-align: center;
  font-size: 15px;
}
</style>