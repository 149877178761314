import request from '@/utils/request'
import qs from 'qs'
// 发稿
export function showallqikan (page,limit) {
  let data = {
    page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/author/showallqikan',
    method: 'post',
    data
  })
}
// 期刊是否启用
export function resultMsg (qiKanId) {
  return request({
    url: '/evaluation/resultMsg?qiKanId=' + qiKanId,
    method: 'get',
  })
}
// 期刊详情
export function showoneqikan (id,qk_id) {
  let data = {
   id,qk_id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/showoneqikan',
    method: 'post',
    data
  })
}
// 收藏期刊
export function addfavorites (qk_id,a_id) {
  let data = {
   qk_id,a_id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/addfavorites',
    method: 'post',
    data
  })
}
// 取消期刊收藏
export function deleteaFvorites (qk_id,a_id) {
  let data = {
   qk_id,a_id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/deleteaFvorites',
    method: 'post',
    data
  })
}
// 查看期刊分类
export function Academic () {
  let data = {
	
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Academic',
    method: 'post',
    data
  })
}

// 查看收录
export function Include () {
  let data = {
 
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Include',
    method: 'post',
    data
  })
}
// 期刊分类加收录
export function Classification (name,page,limit,type_id,include_number) {
  let data = {
	name,page,limit,type_id,include_number
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Classification',
    method: 'post',
    data
  })
}

// 查看已经收藏期刊
export function seefavorites (a_id,page,limit) {
  let data = {
	a_id,page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/author/selectFavoriteByAuth',
    method: 'post',
    data
  })
}
